.ant-page-header-heading-extra {
  text-align: right;
}
/* .ant-row{
  flex-direction: row-reverse!important;
} */
.captcha > div > div {
  width: 100% !important;
}

.ant-card-meta-description {
  min-height: 50px;
}

#course-videos {
  height: 780px;
  overflow-y: scroll;
}

#show-list-mobile {
  float: right;
}

.ant-table-row:hover {
  cursor: pointer;
}

.form-links button {
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0;
  color: #F1A107;
}

.ant-btn-dangerous.ant-btn-primary { 
  border-color: #F1A107 !important;
  background: #F1A107!important;
  border-radius: 10px;
  height: 60px;
  font-size: 20px;
  font-weight: bold;
}

.form-links {
  font-size: 12px;
}

.form-links button:focus {
  outline: none;
}

.form-links button:hover {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  #show-list-mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #course-videos {
    display: none;
  }

  .hide-mob {
    display: none;
  }
}